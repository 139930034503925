<template>
  <div
      class="w-100"

      @wheel="onWheel"
  >

    <b-form-group
        :label-for="name+ (listIndex != -1?('_'+(listIndex+1)):'')"
        :label="displayLabel?capitalize($tc(name)):null"
        class="w-100 mb-0"
    >
      <validation-provider
          v-if="isRequired"
          #default="{ errors }"
          :name="$tc(name)+ (listIndex != -1?(' '+(listIndex+1)):'')"
          rules="required"
      >
        <v-select
            :id="name+ (listIndex != -1?('_'+(listIndex+1)):'')"
            :multiple="multiple"
            v-model="localModel"
            :label="label"
            :options="optionsLocal"
            :placeholder="placeholder!=''?placeholder:($t('Choose a ') + $tc(name).toLowerCase())"
            :selectable="(option) => !option.disabled"
            :clearable="clearable"
            :disabled="disabled"
            :append-to-body="appendToBody"
            :calculate-position="withPopper"
            :getOptionKey="getOptionKey"
            :filter="filter"
            :reduce="reduce?(label=>label[reduce]):(label=>label)"
            :class="size==''?false:('select-size-'+size)"
            :taggable="taggable"
            :push-tag="pushTags"
            class="w-100"

        >
<!--          @search="onSearch"-->
          <template v-slot:option="option">
            <slot
                name="customList"
                v-bind:option="option"
            >
              <!-- Default content -->
              <span v-if="option[reduce] == '-'">
                <b-dropdown-divider></b-dropdown-divider>
              </span>
              <span v-else-if="label != 'label'">{{ option[label] }}</span>
              <span v-else>{{ option[name] }}</span>
            </slot>
          </template>

          <template
              v-if="allowAdd"
              #list-header
          >
            <slot name="list-header">
              <li
                  @click.prevent.stop="$emit('addCallback')"
                  class="add-new-client-header d-flex align-items-center my-50 cursor-pointer"
              >
                <icon icon="plus"/>
                <span class="align-middle ml-50">{{ $t(addText) }}</span>
              </li>
            </slot>
          </template>

          <template #no-options="{ search, searching, loading }">
            {{ $t('NoResult') }}
          </template>
        </v-select>


        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>

      <v-select
          v-else
          :id="name+ (listIndex != -1?('_'+(listIndex+1)):'')"
          :multiple="multiple"
          v-model="localModel"
          :label="label"
          :options="optionsLocal"
          :placeholder="placeholder!=''?placeholder:($t('Choose a ') + $tc(name).toLowerCase())"
          :selectable="(option) => !option.disabled"
          :clearable="clearable"
          :disabled="disabled"
          :append-to-body="appendToBody"
          :calculate-position="withPopper"
          :getOptionKey="getOptionKey"
          :filter="filter"
          :reduce="reduce?(label=>label[reduce]):(label=>label)"
          :class="size==''?false:('select-size-'+size)"
          :taggable="taggable"
          :push-tag="pushTags"
          class="w-100"

      >
<!--        @search="onSearch"-->
        <template v-slot:option="option">
          <slot
              name="customList"
              v-bind:option="option"
          >
            <!-- Default content -->
            <span v-if="option[reduce] == '-'">
              <b-dropdown-divider></b-dropdown-divider>
            </span>
            <span v-else-if="label != 'label'">{{ option[label] }}</span>
            <span v-else>{{ option[name] }}</span>
          </slot>
        </template>

        <template
            v-if="allowAdd"
            #list-header
        >
          <slot name="list-header">
            <li
                @click.prevent.stop="$emit('addCallback')"
                class="add-new-client-header d-flex align-items-center my-50 cursor-pointer"
            >
              <icon icon="plus"/>
              <span class="align-middle ml-50">{{ $t(addText) }}</span>
            </li>
          </slot>
        </template>

        <template #no-options="{ search, searching, loading }">
          {{ $t('NoResult') }}
        </template>
      </v-select>

    </b-form-group>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { ValidationProvider }              from 'vee-validate'
import { required }                        from '@/utils/validations/validations'
import { capitalize }                      from '@/utils/filter'
import { createPopper }                    from '@popperjs/core'

import vSelect                        from 'vue-select'
import { comparableString, isObject } from '../../utils/utils'
import store                          from '../../store'

export default {
  components: {
    vSelect,
    ValidationProvider
  },
  props: {
    model: {
      // required: true
    },
    name: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },

    // Not required
    displayLabel: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: '_display'
    },
    reduce: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    allowAdd: {
      type: Boolean,
      default: false
    },
    addText: {
      type: String,
      default: 'Add'
    },
    placeholder: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: -1
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    getDetailedObject: {
      type: Boolean,
      default: false
    },
    dispatchUrl: {
      type: String,
      default: ''
    },
    filter: {
      type: Function,
      // default(options, search) {
      //   return options.filter(option => {
      //     let label = this.getOptionLabel(option);
      //     if (typeof label === "number") {
      //       label = label.toString();
      //     }
      //     return this.filterBy(option, label, search);
      //   });
      // }
    },
    size: {
      type: String,
      default: ''
    },
    clearAfterChoice: {
      type: Boolean,
      default: false
    },
    taggable: {
      type: Boolean,
      default: false
    },
    pushTags: {
      type: Boolean,
      default: false
    },
    allowWheel: {
      type: Boolean,
      default: false
    },
    dropdownParentSizeMultiply: {
      type: Number,
      default: null
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref(props.model)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const optionsLocal = computed(() => {
      return props.options
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.model, val => {
      localModel.value = val
    })

    watch(localModel, val => {
      if (val != null && Object.keys(val).length == 2 && props.getDetailedObject && props.dispatchUrl != null) {

        store.dispatch(props.dispatchUrl, val.id)
            .then(object => {
              // console.log(object)
              customEmit(object)

              // emit('update:model', object)
              // emit('input', object)
              // emit('updated')
            })
      } else {
        customEmit(val)
        // emit('update:model', val)
        // emit('input', val)
        // emit('updated')
      }

    })

    watch(optionsLocal, val => {
      if (props.clearAfterChoice) {
        localModel.value = null
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const customEmit = val => {
      // console.log(val)
      emit('update:model', val)
      emit('input', val)
      emit('updated')
    }

    const getOptionKey = (option) => {
      if (typeof option === 'object' && option._uuid) {
        return option._uuid
      } else if (typeof option === 'object' && option.id) {
        return option.id
      } else {
        try {
          return JSON.stringify(option)
        } catch (e) {
          return console.warn(
              `[vue-select warn]: Could not stringify option ` +
              `to generate unique key. Please provide 'getOptionKey' prop ` +
              `to return a unique key for each option.\n` +
              'https://vue-select.org/api/props.html#getoptionkey'
          )
          return null
        }
      }
    }

    const withPopper = (dropdownList, component, { width }) => {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      // dropdownList.style.width = width
      // dropdownList.style.width = component.$el.offsetParent.offsetWidth + 'px'
      // console.log(dropdownList)
      // console.log(component)
      // console.log(component.$el.offsetParent.offsetWidth)
      // console.log(width)

      if (props.dropdownParentSizeMultiply != null) {
        dropdownList.style.width = (component.$el.offsetParent.offsetWidth * props.dropdownParentSizeMultiply) + 'px'
      } else {
        dropdownList.style.width = width
      }

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom-end',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({ state }) {
              component.$el.classList.toggle(
                  'drop-up',
                  state.placement === 'top'
              )
            },
          },
        ],
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    }

    const onWheel = wheel => {
      if (props.allowWheel == true) {
        if (props.reduce === undefined) {
          let currentOptionIndex = props.options.findIndex(o => o == localModel.value)

          if (wheel.wheelDeltaY < 0) {
            if (currentOptionIndex + 1 < props.options.length) {
              localModel.value = props.options[currentOptionIndex + 1]
            }
          } else {
            if (currentOptionIndex - 1 >= 0) {
              localModel.value = props.options[currentOptionIndex - 1]
            }
          }
        } else {
          let currentOptionIndex = props.options.findIndex(o => o[props.reduce] == localModel.value)

          if (wheel.wheelDeltaY < 0) {
            if (currentOptionIndex + 1 < props.options.length) {
              localModel.value = props.options[currentOptionIndex + 1][props.reduce]
            }
          } else {
            if (currentOptionIndex - 1 >= 0) {
              localModel.value = props.options[currentOptionIndex - 1][props.reduce]
            }
          }
        }
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    // if (props.model) {
    //   if (isObject(props.model)) {
    //     if ((props.label in props.model)) {
    //       localModel.value = props.model
    //     } else {
    //       localModel.value = props.options.find(o => o.id == props.model.id)
    //     }
    //   } else {
    //     localModel.value = props.options.find(o => o.id == props.model)
    //   }
    // }

    return {
      // Components
      capitalize,
      required,

      // Data
      localModel,

      // Computed
      optionsLocal,

      // Methods
      getOptionKey,
      withPopper,
      onWheel,
    }
  },
  data () {
    return {
      timeout: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    onSearch (search, loading) {
      if (search.length) {
        loading(true)

        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.search(loading, search, this)
        }, 300)
      }
    },
    search (loading, search, vm) {
      // console.log(search)
      let normalizedSearch = comparableString(search)
      // console.log(normalizedSearch)

      vm.options = this.options.filter(o => {
        // console.log(
        //     [
        //       o[this.label],
        //       comparableString(o[this.label]),
        //       comparableString(o[this.label]).includes(normalizedSearch)
        //     ]
        // )
        return comparableString(o[this.label]).includes(normalizedSearch)
      })

      console.log(vm.options)

      loading(false)
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>